<template>
  <div>
    <Header titleurl="4"></Header>
    <div class="boxStatus">
      <Menu></Menu>
      <div class="RightShow">
        <div class="resumeTitle">谁下载了我的简历</div>
        <el-table :data="tableData" border style="width: 100%" >
          <el-table-column prop="companyname" label="公司名称">
          </el-table-column>
          <el-table-column prop="downloadtime" label="下载时间" >
          </el-table-column>
          <el-table-column fixed="right" label="操作" >
            <template slot-scope="scope">
              <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../../components/header';
import Footer from '../../components/footer';
import Menu from '../../components/menu';
import Service from '../../components/serviceBox';

export default {
  name: 'resumeStatus',
  components: {
    Header,
    Footer,
    Menu,
    Service,
  },
  data() {
    return {
      tableData:[{
        companyname:'河南凯业股份有限公司',
        downloadtime:'2020-11-10'
      }]
    };
  },
  mounted() {},
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="../../assets/style/jobwanted/resumeDownload.css" scoped>
</style>